export function VideoPauseClarifyIcon(
  props: React.ComponentPropsWithoutRef<'svg'>,
) {
  return (
    <svg aria-hidden="true" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M2.25 3.26515C2.25 2.42836 2.94645 1.75 3.80556 1.75H4.19444C5.05355 1.75 5.75 2.42836 5.75 3.26515V12.7348C5.75 13.5717 5.05355 14.25 4.19444 14.25H3.80556C2.94645 14.25 2.25 13.5717 2.25 12.7348V3.26515Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M10.25 3.26515C10.25 2.42836 10.9464 1.75 11.8056 1.75H12.1944C13.0536 1.75 13.75 2.42836 13.75 3.26515V12.7348C13.75 13.5717 13.0536 14.25 12.1944 14.25H11.8056C10.9464 14.25 10.25 13.5717 10.25 12.7348V3.26515Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  );
}
