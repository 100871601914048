export function ClockClarifyIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg aria-hidden="true" viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 8C1.5 6.27609 2.18482 4.62279 3.40381 3.40381C4.62279 2.18482 6.27609 1.5 8 1.5C9.72391 1.5 11.3772 2.18482 12.5962 3.40381C13.8152 4.62279 14.5 6.27609 14.5 8C14.5 9.72391 13.8152 11.3772 12.5962 12.5962C11.3772 13.8152 9.72391 14.5 8 14.5C6.27609 14.5 4.62279 13.8152 3.40381 12.5962C2.18482 11.3772 1.5 9.72391 1.5 8ZM8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0ZM8.5 4.75C8.5 4.55109 8.42098 4.36032 8.28033 4.21967C8.13968 4.07902 7.94891 4 7.75 4C7.55109 4 7.36032 4.07902 7.21967 4.21967C7.07902 4.36032 7 4.55109 7 4.75V8.25C7.00003 8.39978 7.04492 8.54612 7.12886 8.67017C7.21281 8.79421 7.33197 8.89028 7.471 8.946L9.971 9.946C10.1541 10.0129 10.3561 10.0058 10.5341 9.92633C10.7121 9.84684 10.8522 9.70115 10.9246 9.52014C10.997 9.33914 10.9961 9.13705 10.9221 8.95669C10.8481 8.77634 10.7067 8.63191 10.528 8.554L8.5 7.742V4.75Z"
        fill="currentColor"
      />
    </svg>
  );
}
