export function LocationClarifyIcon(
  props: React.ComponentPropsWithoutRef<'svg'>,
) {
  return (
    <svg aria-hidden="true" viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.536 3.46402C12.0004 3.92833 12.3689 4.47958 12.6202 5.0863C12.8716 5.69301 13.0009 6.3433 13.0009 7.00002C13.0009 7.65674 12.8716 8.30702 12.6202 8.91374C12.3689 9.52045 12.0004 10.0717 11.536 10.536L8 14.07L4.464 10.535C3.99965 10.0707 3.6313 9.51939 3.38 8.91269C3.12869 8.30598 2.99934 7.65571 2.99934 6.99902C2.99934 6.34232 3.12869 5.69206 3.38 5.08535C3.6313 4.47864 3.99965 3.92737 4.464 3.46302C4.92836 2.99866 5.47963 2.63032 6.08633 2.37901C6.69304 2.1277 7.34331 1.99836 8 1.99836C8.6567 1.99836 9.30696 2.1277 9.91367 2.37901C10.5204 2.63032 11.0716 2.99866 11.536 3.46302V3.46402ZM12.596 11.596C13.5051 10.687 14.1242 9.52881 14.3751 8.26792C14.6259 7.00703 14.4972 5.70006 14.0053 4.51231C13.5133 3.32456 12.6802 2.30937 11.6113 1.59511C10.5423 0.880866 9.2856 0.499634 8 0.499634C6.7144 0.499634 5.45767 0.880866 4.38874 1.59511C3.31981 2.30937 2.48669 3.32456 1.99473 4.51231C1.50278 5.70006 1.37409 7.00703 1.62494 8.26792C1.87579 9.52881 2.49491 10.687 3.404 11.596L6.939 15.132C7.0783 15.2714 7.2437 15.382 7.42576 15.4574C7.60781 15.5329 7.80294 15.5717 8 15.5717C8.19707 15.5717 8.3922 15.5329 8.57425 15.4574C8.7563 15.382 8.9217 15.2714 9.061 15.132L12.596 11.596ZM8 9.00002C8.53044 9.00002 9.03914 8.7893 9.41422 8.41423C9.78929 8.03916 10 7.53045 10 7.00002C10 6.46958 9.78929 5.96089 9.41422 5.5858C9.03914 5.21073 8.53044 5.00002 8 5.00002C7.46957 5.00002 6.96086 5.21073 6.58579 5.5858C6.21072 5.96089 6 6.46958 6 7.00002C6 7.53045 6.21072 8.03916 6.58579 8.41423C6.96086 8.7893 7.46957 9.00002 8 9.00002Z"
        fill="currentColor"
      />
    </svg>
  );
}
