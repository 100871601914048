export function BellOffClarifyIcon(
  props: React.ComponentPropsWithoutRef<'svg'>,
) {
  return (
    <svg aria-hidden="true" viewBox="0 0 16 16" {...props}>
      <path
        d="M13.25 3.5V8L15.25 12.25H4.5M1.75 10.125L2.75 8V6C2.75 3.10051 5.10051 0.75 8 0.75C8 0.75 9.60938 0.750015 10.5938 1.24998"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M5 12.75C5 12.75 5 15.25 8 15.25C11 15.25 11 12.75 11 12.75"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M15 1.5L1.5 15"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </svg>
  );
}
