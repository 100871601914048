export function LinkClarifyIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg aria-hidden="true" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M11.9454 9L12.9757 7.9697C14.3414 6.60401 14.3414 4.38988 12.9757 3.02424C11.6101 1.65859 9.39598 1.65859 8.0303 3.02424L7 4.05454"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.05454 7L3.02424 8.0303C1.65859 9.39598 1.65859 11.6101 3.02424 12.9757C4.38988 14.3414 6.60401 14.3414 7.9697 12.9757L9 11.9454"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 6L6 10"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
