export function LogoutClarifyIcon(
  props: React.ComponentPropsWithoutRef<'svg'>,
) {
  return (
    <svg aria-hidden="true" viewBox="0 0 16 16" {...props}>
      <path
        d="M2 2.75C2 1.784 2.784 1 3.75 1H6.25C6.44891 1 6.63968 1.07902 6.78033 1.21967C6.92098 1.36032 7 1.55109 7 1.75C7 1.94891 6.92098 2.13968 6.78033 2.28033C6.63968 2.42098 6.44891 2.5 6.25 2.5H3.75C3.6837 2.5 3.62011 2.52634 3.57322 2.57322C3.52634 2.62011 3.5 2.6837 3.5 2.75V13.25C3.5 13.388 3.612 13.5 3.75 13.5H6.25C6.44891 13.5 6.63968 13.579 6.78033 13.7197C6.92098 13.8603 7 14.0511 7 14.25C7 14.4489 6.92098 14.6397 6.78033 14.7803C6.63968 14.921 6.44891 15 6.25 15H3.75C3.28587 15 2.84075 14.8156 2.51256 14.4874C2.18437 14.1592 2 13.7141 2 13.25V2.75ZM12.44 7.25L10.47 5.28C10.3713 5.18833 10.2991 5.07173 10.2611 4.94248C10.2231 4.81323 10.2206 4.67612 10.254 4.54559C10.2874 4.41507 10.3553 4.29596 10.4507 4.20082C10.5461 4.10568 10.6654 4.03803 10.796 4.005C10.9264 3.97158 11.0633 3.97391 11.1925 4.01175C11.3217 4.04958 11.4383 4.12152 11.53 4.22L14.78 7.47C14.9205 7.61063 14.9993 7.80125 14.9993 8C14.9993 8.19875 14.9205 8.38937 14.78 8.53L11.53 11.78C11.4383 11.8787 11.3217 11.9509 11.1925 11.9889C11.0632 12.0269 10.9261 12.0294 10.7956 11.996C10.6651 11.9626 10.546 11.8947 10.4508 11.7993C10.3557 11.7039 10.288 11.5846 10.255 11.454C10.2216 11.3236 10.2239 11.1867 10.2617 11.0575C10.2996 10.9283 10.3715 10.8117 10.47 10.72L12.44 8.75H6.75C6.55109 8.75 6.36032 8.67098 6.21967 8.53033C6.07902 8.38968 6 8.19891 6 8C6 7.80109 6.07902 7.61032 6.21967 7.46967C6.36032 7.32902 6.55109 7.25 6.75 7.25H12.44Z"
        fill="currentColor"
      />
    </svg>
  );
}
