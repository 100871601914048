export function CalendarClarifyIcon(
  props: React.ComponentPropsWithoutRef<'svg'>,
) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 4.25C0 2.73122 1.23122 1.5 2.75 1.5H13.25C14.7688 1.5 16 2.73122 16 4.25V12.25C16 13.7688 14.7688 15 13.25 15H2.75C1.23122 15 0 13.7688 0 12.25V4.25ZM2.75 3C2.05964 3 1.5 3.55964 1.5 4.25V12.25C1.5 12.9404 2.05964 13.5 2.75 13.5H13.25C13.9404 13.5 14.5 12.9404 14.5 12.25V4.25C14.5 3.55964 13.9404 3 13.25 3H2.75Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 0C4.41421 0 4.75 0.335786 4.75 0.75V3.5C4.75 3.91421 4.41421 4.25 4 4.25C3.58579 4.25 3.25 3.91421 3.25 3.5V0.75C3.25 0.335786 3.58579 0 4 0Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 0C12.4142 0 12.75 0.335786 12.75 0.75V3.5C12.75 3.91421 12.4142 4.25 12 4.25C11.5858 4.25 11.25 3.91421 11.25 3.5V0.75C11.25 0.335786 11.5858 0 12 0Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 6.75C3 6.33579 3.33579 6 3.75 6H12.25C12.6642 6 13 6.33579 13 6.75C13 7.16421 12.6642 7.5 12.25 7.5H3.75C3.33579 7.5 3 7.16421 3 6.75Z"
        fill="currentColor"
      />
    </svg>
  );
}
