export function PeopleClarifyIcon(
  props: React.ComponentPropsWithoutRef<'svg'>,
) {
  return (
    <svg aria-hidden="true" viewBox="0 0 16 16" {...props}>
      <path
        d="M15.25 8A7.25 7.25 0 11.75 8a7.25 7.25 0 0114.5 0z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M10.25 6a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zM3.197 13A6.237 6.237 0 018 10.75c1.93 0 3.656.875 4.802 2.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </svg>
  );
}
