export function CheckClarifyIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.78 4.21995C13.9204 4.36057 13.9993 4.5512 13.9993 4.74995C13.9993 4.9487 13.9204 5.13932 13.78 5.27995L6.52997 12.53C6.38934 12.6704 6.19872 12.7493 5.99997 12.7493C5.80122 12.7493 5.61059 12.6704 5.46997 12.53L2.21997 9.27995C2.08749 9.13777 2.01537 8.94973 2.01879 8.75542C2.02222 8.56112 2.10093 8.37574 2.23835 8.23832C2.37576 8.10091 2.56115 8.0222 2.75545 8.01877C2.94975 8.01534 3.13779 8.08747 3.27997 8.21995L5.99997 10.9399L12.72 4.21995C12.8606 4.0795 13.0512 4.00061 13.25 4.00061C13.4487 4.00061 13.6394 4.0795 13.78 4.21995Z"
        fill="currentColor"
      />
    </svg>
  );
}
