import React from 'react';

interface DateNumbersClarifyIconProps extends React.SVGProps<SVGSVGElement> {
  currentDate?: number;
  isActive?: boolean;
}

export function DateNumbersClarifyIcon({
  className,
  currentDate,
  isActive,
  ...props
}: DateNumbersClarifyIconProps) {
  return (
    <svg
      className={`${className} date-numbers-icon ${isActive ? 'text-brand-500 dark:text-brand-400' : ''}`}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 4.25A2.75 2.75 0 012.75 1.5h10.5A2.75 2.75 0 0116 4.25v8A2.75 2.75 0 0113.25 15H2.75A2.75 2.75 0 010 12.25v-8zM2.75 3c-.69 0-1.25.56-1.25 1.25v8c0 .69.56 1.25 1.25 1.25h10.5c.69 0 1.25-.56 1.25-1.25v-8c0-.69-.56-1.25-1.25-1.25H2.75z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 0a.75.75 0 01.75.75v.75a.75.75 0 01-1.5 0V.75A.75.75 0 014 0zM12 0a.75.75 0 01.75.75v.75a.75.75 0 01-1.5 0V.75A.75.75 0 0112 0z"
        fill="currentColor"
      />
      {currentDate && (
        <text
          x="50%"
          y="50%"
          fontSize="8px"
          fontWeight="bold"
          letterSpacing="-0.5px"
          textAnchor="middle"
          dominantBaseline="central"
          className="date-number visible"
        >
          {currentDate}
        </text>
      )}
    </svg>
  );
}
