export function EnumFilledClarifyIcon(
  props: React.ComponentPropsWithoutRef<'svg'>,
) {
  return (
    <svg aria-hidden="true" viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM5.2996 6.23966C5.01774 5.93613 4.54319 5.91855 4.23966 6.2004C3.93613 6.48226 3.91855 6.95681 4.2004 7.26034L7.45041 10.7603C7.59231 10.9132 7.79145 11 8 11C8.20855 11 8.40769 10.9132 8.54959 10.7603L11.7996 7.26034C12.0814 6.95681 12.0639 6.48226 11.7603 6.2004C11.4568 5.91855 10.9823 5.93613 10.7004 6.23966L8 9.14779L5.2996 6.23966Z"
        fill="currentColor"
      />
    </svg>
  );
}
