export function SettingsAltClarifyIcon(
  props: React.ComponentPropsWithoutRef<'svg'>,
) {
  return (
    <svg aria-hidden="true" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M9.32576 4.21212H1.75M9.32576 4.21212C9.32576 2.8518 10.4276 1.75 11.7879 1.75C13.1482 1.75 14.25 2.8518 14.25 4.21212C14.25 5.57245 13.1482 6.67424 11.7879 6.67424C10.4276 6.67424 9.32576 5.57245 9.32576 4.21212ZM14.25 11.7879H8.18939M8.18939 11.7879C8.18939 13.1482 7.08758 14.25 5.72727 14.25C4.36695 14.25 3.26515 13.1482 3.26515 11.7879M8.18939 11.7879C8.18939 10.4276 7.08758 9.32576 5.72727 9.32576C4.36695 9.32576 3.26515 10.4276 3.26515 11.7879M3.26515 11.7879H1.75"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
