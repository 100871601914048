export function CheckboxClarifyIcon(
  props: React.ComponentPropsWithoutRef<'svg'>,
) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <rect
        x="1.75"
        y="1.75"
        width="12.5"
        height="12.5"
        rx="1.75"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M11.7803 5.21967C12.0732 5.51256 12.0732 5.98744 11.7803 6.28033L7.28033 10.7803C6.98744 11.0732 6.51256 11.0732 6.21967 10.7803L4.21967 8.78033C3.92678 8.48744 3.92678 8.01256 4.21967 7.71967C4.51256 7.42678 4.98744 7.42678 5.28033 7.71967L6.75 9.18934L10.7197 5.21967C11.0126 4.92678 11.4874 4.92678 11.7803 5.21967Z"
        fill="currentColor"
      />
    </svg>
  );
}
