export function SidebarRightOpenClarifyIcon(
  props: React.ComponentPropsWithoutRef<'svg'>,
) {
  return (
    <svg aria-hidden="true" viewBox="0 0 16 16" {...props}>
      <path
        d="M1.707 1.707A2.5 2.5 0 013.474.975h3.88a.775.775 0 110 1.55h-3.88a.95.95 0 00-.949.95v9.05a.95.95 0 00.95.95h3.878a.775.775 0 010 1.55H3.474a2.5 2.5 0 01-2.499-2.5v-9.05a2.5 2.5 0 01.732-1.768zm7.889.043c0-.428.347-.775.775-.775h2.155a2.5 2.5 0 012.499 2.5v9.05a2.5 2.5 0 01-2.5 2.5h-2.154a.775.775 0 01-.775-.775V1.75z"
        fill="currentColor"
      />
    </svg>
  );
}
