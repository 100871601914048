export function SidebarRightClarifyIcon(
  props: React.ComponentPropsWithoutRef<'svg'>,
) {
  return (
    <svg aria-hidden="true" viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.70697 1.70695C2.17565 1.23827 2.81131 0.974976 3.47411 0.974976H7.35342C7.78144 0.974976 8.12842 1.32195 8.12842 1.74998C8.12842 2.178 7.78144 2.52498 7.35342 2.52498H3.47411C3.22239 2.52498 2.98097 2.62497 2.80298 2.80297L2.80297 2.80298C2.62497 2.98097 2.52498 3.22239 2.52498 3.47411V12.5258C2.52498 12.7775 2.62496 13.0189 2.80299 13.197L2.803 13.197C2.98098 13.375 3.22238 13.475 3.47411 13.475H7.35342C7.78144 13.475 8.12842 13.822 8.12842 14.25C8.12842 14.678 7.78144 15.025 7.35342 15.025H3.47411C2.81131 15.025 2.17563 14.7617 1.70694 14.293C1.23828 13.8243 0.974976 13.1886 0.974976 12.5258V3.47411C0.974976 2.81131 1.23827 2.17565 1.70695 1.70697C1.70696 1.70696 1.70696 1.70696 1.70697 1.70695ZM9.59566 1.74998C9.59566 1.32195 9.94264 0.974976 10.3707 0.974976H12.5258C13.1886 0.974976 13.8243 1.23828 14.293 1.70694C14.7617 2.17563 15.025 2.81131 15.025 3.47411V12.5258C15.025 13.1886 14.7617 13.8243 14.293 14.293C13.8243 14.7617 13.1886 15.025 12.5258 15.025H10.3707C9.94264 15.025 9.59566 14.678 9.59566 14.25V1.74998ZM11.1457 2.52498V13.475H12.5258C12.7775 13.475 13.0189 13.375 13.197 13.197C13.375 13.0189 13.475 12.7775 13.475 12.5258V3.47411C13.475 3.22238 13.375 2.98098 13.197 2.803L13.197 2.80299C13.0189 2.62496 12.7775 2.52498 12.5258 2.52498H11.1457Z"
        fill="currentColor"
      />
    </svg>
  );
}
