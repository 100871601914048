export function SpinnerClarifyIcon(
  props: React.ComponentPropsWithoutRef<'svg'>,
) {
  return (
    <svg
      aria-hidden="true"
      viewBox="0 0 16 16"
      width={16}
      height={16}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 7.99998C16 12.4183 12.4183 16 8 16C3.58173 16 0 12.4183 0 7.99998C0 3.58172 3.58173 0 8 0C12.4183 0 16 3.58172 16 7.99998ZM1.45303 7.99998C1.45303 11.6158 4.38421 14.5469 8 14.5469C11.6158 14.5469 14.547 11.6158 14.547 7.99998C14.547 4.3842 11.6158 1.45303 8 1.45303C4.38421 1.45303 1.45303 4.3842 1.45303 7.99998Z"
        fill="currentColor"
        opacity="0.25"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0348 6.15201C15.4229 6.05008 15.658 5.65133 15.5213 5.27409C15.2469 4.5171 14.8594 3.80454 14.3707 3.16115C13.7352 2.32454 12.9412 1.62128 12.034 1.09153C11.1268 0.561779 10.1241 0.215909 9.08317 0.0736673C8.28266 -0.0357246 7.47162 -0.0230362 6.67752 0.110066C6.28181 0.176394 6.05008 0.577114 6.15202 0.965191C6.25397 1.35327 6.65111 1.58094 7.04808 1.5226C7.65618 1.43324 8.27506 1.42977 8.88644 1.51332C9.73828 1.62972 10.5589 1.91278 11.3013 2.3463C12.0438 2.77984 12.6936 3.35537 13.2136 4.04003C13.5868 4.5314 13.888 5.07208 14.109 5.6456C14.2533 6.02 14.6467 6.25397 15.0348 6.15201Z"
        fill="currentColor"
        opacity="0.5"
      />
    </svg>
  );
}
