export function IntegrationsClarifyIcon(
  props: React.ComponentPropsWithoutRef<'svg'>,
) {
  return (
    <svg aria-hidden="true" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M13.75 0.75C13.75 0.335786 13.4142 0 13 0C12.5858 0 12.25 0.335786 12.25 0.75V2.25H10.75C10.3358 2.25 10 2.58579 10 3C10 3.41421 10.3358 3.75 10.75 3.75H12.25V5.25C12.25 5.66421 12.5858 6 13 6C13.4142 6 13.75 5.66421 13.75 5.25V3.75H15.25C15.6642 3.75 16 3.41421 16 3C16 2.58579 15.6642 2.25 15.25 2.25H13.75V0.75Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1.75C0 0.783502 0.783502 0 1.75 0H5.25C6.2165 0 7 0.783502 7 1.75V5.25C7 6.2165 6.2165 7 5.25 7H1.75C0.783502 7 0 6.2165 0 5.25V1.75ZM1.75 1.5C1.61193 1.5 1.5 1.61193 1.5 1.75V5.25C1.5 5.38807 1.61193 5.5 1.75 5.5H5.25C5.38807 5.5 5.5 5.38807 5.5 5.25V1.75C5.5 1.61193 5.38807 1.5 5.25 1.5H1.75Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 10.75C0 9.7835 0.783502 9 1.75 9H5.25C6.2165 9 7 9.7835 7 10.75V14.25C7 15.2165 6.2165 16 5.25 16H1.75C0.783502 16 0 15.2165 0 14.25V10.75ZM1.75 10.5C1.61193 10.5 1.5 10.6119 1.5 10.75V14.25C1.5 14.3881 1.61193 14.5 1.75 14.5H5.25C5.38807 14.5 5.5 14.3881 5.5 14.25V10.75C5.5 10.6119 5.38807 10.5 5.25 10.5H1.75Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 10.75C9 9.7835 9.7835 9 10.75 9H14.25C15.2165 9 16 9.7835 16 10.75V14.25C16 15.2165 15.2165 16 14.25 16H10.75C9.7835 16 9 15.2165 9 14.25V10.75ZM10.75 10.5C10.6119 10.5 10.5 10.6119 10.5 10.75V14.25C10.5 14.3881 10.6119 14.5 10.75 14.5H14.25C14.3881 14.5 14.5 14.3881 14.5 14.25V10.75C14.5 10.6119 14.3881 10.5 14.25 10.5H10.75Z"
        fill="currentColor"
      />
    </svg>
  );
}
