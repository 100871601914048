export function PhoneClarifyIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg aria-hidden="true" viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.10714 1.25H2.47727C1.79947 1.25 1.25 1.79947 1.25 2.47727C1.25 9.25531 6.74469 14.75 13.5227 14.75C14.2005 14.75 14.75 14.2005 14.75 13.5227V10.8929L11.8571 8.96429L10.3571 10.4643C10.0966 10.7248 9.70724 10.8059 9.37989 10.6368C8.8247 10.35 7.92379 9.81106 7.03571 8.96429C6.12646 8.09733 5.59373 7.16313 5.32634 6.59782C5.17649 6.28101 5.26324 5.91533 5.51105 5.66752L7.03571 4.14286L5.10714 1.25Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </svg>
  );
}
