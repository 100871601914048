export function RefreshClarifyIcon(
  props: React.ComponentPropsWithoutRef<'svg'>,
) {
  return (
    <svg aria-hidden="true" viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.53033 2.53033C7.82322 2.23744 7.82322 1.76256 7.53033 1.46967C7.23744 1.17678 6.76256 1.17678 6.46967 1.46967L4.46967 3.46967C4.32902 3.61032 4.25 3.80109 4.25 4C4.25 4.19891 4.32902 4.38968 4.46967 4.53033L6.46967 6.53033C6.76256 6.82322 7.23744 6.82322 7.53033 6.53033C7.82322 6.23744 7.82322 5.76256 7.53033 5.46967L6.81066 4.75H8.94737C11.3604 4.75 13.25 6.59903 13.25 8.8V9C13.25 9.41421 13.5858 9.75 14 9.75C14.4142 9.75 14.75 9.41421 14.75 9V8.8C14.75 5.69903 12.1154 3.25 8.94737 3.25H6.81066L7.53033 2.53033Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.53033 9.46967C9.23744 9.17678 8.76256 9.17678 8.46967 9.46967C8.17678 9.76256 8.17678 10.2374 8.46967 10.5303L9.18934 11.25H7.05263C4.63964 11.25 2.75 9.40096 2.75 7.2V7C2.75 6.58579 2.41421 6.25 2 6.25C1.58579 6.25 1.25 6.58579 1.25 7V7.2C1.25 10.301 3.88464 12.75 7.05263 12.75H9.18934L8.46967 13.4697C8.17678 13.7626 8.17678 14.2374 8.46967 14.5303C8.76256 14.8232 9.23744 14.8232 9.53033 14.5303L11.5303 12.5303C11.671 12.3897 11.75 12.1989 11.75 12C11.75 11.8011 11.671 11.6103 11.5303 11.4697L9.53033 9.46967Z"
        fill="currentColor"
      />
    </svg>
  );
}
