export function SearchClarifyIcon(
  props: React.ComponentPropsWithoutRef<'svg'>,
) {
  return (
    <svg aria-hidden="true" viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 1C3.685 1 1 3.685 1 7a5.999 5.999 0 009.68 4.74l2.79 2.79a.75.75 0 101.06-1.06l-2.79-2.79A5.974 5.974 0 0013 7c0-3.307-2.686-5.99-5.998-6H7zM2.5 7c0-2.486 2.013-4.5 4.499-4.5C9.488 2.509 11.5 4.525 11.5 7c0 2.486-2.014 4.5-4.5 4.5A4.499 4.499 0 012.5 7z"
        fill="currentColor"
      />
    </svg>
  );
}
