export function ChangelogClarifyIcon(
  props: React.ComponentPropsWithoutRef<'svg'>,
) {
  return (
    <svg aria-hidden="true" viewBox="0 0 16 16" {...props}>
      <path
        d="M15.25 6.25V2.75C15.25 1.64543 14.3546 0.75 13.25 0.75H2.75C1.64543 0.75 0.75 1.64543 0.75 2.75V13.25C0.75 14.3546 1.64543 15.25 2.75 15.25H7.25M4.75 4.75H11.25M4.75 8.75H7.25M12.4981 10.4026C11.9483 9.6884 11.0314 9.49628 10.3425 10.1503C9.65365 10.8043 9.55667 11.8977 10.0977 12.6713L12.4981 15.25L14.8986 12.6713C15.4396 11.8977 15.3544 10.7974 14.6537 10.1503C13.9529 9.50316 13.0479 9.6884 12.4981 10.4026Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </svg>
  );
}
