export function NoteBookClarifyIcon(
  props: React.ComponentPropsWithoutRef<'svg'>,
) {
  return (
    <svg aria-hidden="true" viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.75 1.5C3.05964 1.5 2.5 2.05964 2.5 2.75V12.25C2.5 12.9404 3.05964 13.5 3.75 13.5H12.2502C12.9406 13.5 13.5002 12.9404 13.5002 12.25V2.75C13.5002 2.05964 12.9406 1.5 12.2502 1.5H3.75ZM1 2.75C1 1.23122 2.23121 0 3.75 0H12.2502C13.769 0 15.0002 1.23122 15.0002 2.75V12.25C15.0002 13.7688 13.769 15 12.2502 15H3.75C2.23122 15 1 13.7688 1 12.25V2.75Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 4.75C9 4.33579 9.33579 4 9.75 4H10.25C10.6642 4 11 4.33579 11 4.75C11 5.16421 10.6642 5.5 10.25 5.5H9.75C9.33579 5.5 9 5.16421 9 4.75Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 7.75C9 7.33579 9.33579 7 9.75 7H10.25C10.6642 7 11 7.33579 11 7.75C11 8.16421 10.6642 8.5 10.25 8.5H9.75C9.33579 8.5 9 8.16421 9 7.75Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.75 0C6.16421 0 6.5 0.335786 6.5 0.75V15.25C6.5 15.6642 6.16421 16 5.75 16C5.33579 16 5 15.6642 5 15.25V0.75C5 0.335786 5.33579 0 5.75 0Z"
        fill="currentColor"
      />
    </svg>
  );
}
