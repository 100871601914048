export function VideoPIPExpandClarifyIcon(
  props: React.ComponentPropsWithoutRef<'svg'>,
) {
  return (
    <svg aria-hidden="true" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M14.25 6.25V2.79286C14.25 1.94076 13.556 1.25 12.75 1.25H2.70946C1.9034 1.25 1.25 1.94076 1.25 2.79286V8.75C1.25 9.60212 1.9034 10.25 2.70946 10.25H5.99324M4.25 4.5V6.99998M4.25 4.5H6.75M4.25 4.5L6.74998 6.99998M10.25 14.75H13.2905C14.0966 14.75 14.75 14.0593 14.75 13.2071V10.25C14.75 9.39788 14.056 8.75 13.25 8.75H10.25C9.44394 8.75 8.75 9.39788 8.75 10.25V13.25C8.75 14.1021 9.44394 14.75 10.25 14.75Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
