export function MenuClarifyIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg aria-hidden="true" viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 3.75C1 3.55109 1.07902 3.36032 1.21967 3.21967C1.36032 3.07902 1.55109 3 1.75 3H14.25C14.4489 3 14.6397 3.07902 14.7803 3.21967C14.921 3.36032 15 3.55109 15 3.75C15 3.94891 14.921 4.13968 14.7803 4.28033C14.6397 4.42098 14.4489 4.5 14.25 4.5H1.75C1.55109 4.5 1.36032 4.42098 1.21967 4.28033C1.07902 4.13968 1 3.94891 1 3.75ZM1.75 11C1.55109 11 1.36032 11.079 1.21967 11.2197C1.07902 11.3603 1 11.5511 1 11.75C1 11.9489 1.07902 12.1397 1.21967 12.2803C1.36032 12.421 1.55109 12.5 1.75 12.5H14.25C14.4489 12.5 14.6397 12.421 14.7803 12.2803C14.921 12.1397 15 11.9489 15 11.75C15 11.5511 14.921 11.3603 14.7803 11.2197C14.6397 11.079 14.4489 11 14.25 11H1.75Z"
        fill="currentColor"
      />
    </svg>
  );
}
