export function VideoCCClarifyIcon(
  props: React.ComponentPropsWithoutRef<'svg'>,
) {
  return (
    <svg aria-hidden="true" viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.63976 6.87944C6.09174 6.4672 5.30895 6.51112 4.81005 7.01C4.26332 7.55672 4.26332 8.4432 4.81005 8.98992C5.30895 9.4888 6.09174 9.53272 6.63976 9.12048C6.90456 8.92128 7.28072 8.97448 7.47992 9.23928C7.67912 9.50408 7.62592 9.88024 7.36112 10.0794C6.34209 10.846 4.88908 10.766 3.96152 9.83848C2.94616 8.82312 2.94616 7.17688 3.96152 6.16149C4.88908 5.23393 6.34209 5.15395 7.36112 5.92048C7.62592 6.11968 7.67912 6.49584 7.47992 6.76064C7.28072 7.02544 6.90456 7.07864 6.63976 6.87944ZM11.8398 6.87944C11.2918 6.4672 10.509 6.51112 10.0101 7.01C9.46328 7.55672 9.46328 8.4432 10.0101 8.98992C10.509 9.4888 11.2918 9.53272 11.8398 9.12048C12.1046 8.92128 12.4807 8.97448 12.6799 9.23928C12.8791 9.50408 12.8259 9.88024 12.5611 10.0794C11.5421 10.846 10.0891 10.766 9.16152 9.83848C8.14616 8.82312 8.14616 7.17688 9.16152 6.16149C10.0891 5.23393 11.5421 5.15395 12.5611 5.92048C12.8259 6.11968 12.8791 6.49584 12.6799 6.76064C12.4807 7.02544 12.1046 7.07864 11.8398 6.87944Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.5 2.88636C0.5 1.56841 1.56841 0.5 2.88636 0.5H13.1136C14.4316 0.5 15.5 1.56842 15.5 2.88636V13.1136C15.5 14.4316 14.4316 15.5 13.1136 15.5H2.88636C1.56842 15.5 0.5 14.4316 0.5 13.1136V2.88636ZM2.88636 2C2.39684 2 2 2.39684 2 2.88636V13.1136C2 13.6032 2.39683 14 2.88636 14H13.1136C13.6032 14 14 13.6032 14 13.1136V2.88636C14 2.39683 13.6032 2 13.1136 2H2.88636Z"
        fill="currentColor"
      />
    </svg>
  );
}
