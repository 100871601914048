export function VideoFullScreenClarifyIcon(
  props: React.ComponentPropsWithoutRef<'svg'>,
) {
  return (
    <svg aria-hidden="true" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M2.75 8.75V11.6136C2.75 12.5174 3.48262 13.25 4.38636 13.25H7.25M8.75 2.75H11.6136C12.5174 2.75 13.25 3.48262 13.25 4.38636V7.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
