export function InboxClarifyIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg aria-hidden="true" viewBox="0 0 16 16" {...props}>
      <path
        d="M14.75 8.25L13.274 2.7c-.229-.858-.96-1.45-1.79-1.45H4.516c-.832 0-1.562.592-1.79 1.45L1.25 8.25m13.5 0v4.767c0 .957-.834 1.733-1.862 1.733H3.112c-1.028 0-1.862-.776-1.862-1.733V8.25m13.5 0h-4.116c-.395 0-.731.245-.971.536A2.149 2.149 0 018 9.55c-.677 0-1.28-.3-1.663-.764-.24-.291-.576-.536-.971-.536H1.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </svg>
  );
}
