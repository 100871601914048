export function AlarmClarifyIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg aria-hidden="true" viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.53033 13.4697C3.82322 13.7626 3.82322 14.2374 3.53033 14.5303L2.28033 15.7803C1.98744 16.0732 1.51256 16.0732 1.21967 15.7803C0.926777 15.4874 0.926777 15.0126 1.21967 14.7197L2.46967 13.4697C2.76256 13.1768 3.23744 13.1768 3.53033 13.4697Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4697 13.4697C12.7626 13.1768 13.2374 13.1768 13.5303 13.4697L14.7803 14.7197C15.0732 15.0126 15.0732 15.4874 14.7803 15.7803C14.4874 16.0732 14.0126 16.0732 13.7197 15.7803L12.4697 14.5303C12.1768 14.2374 12.1768 13.7626 12.4697 13.4697Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 4C8.41421 4 8.75 4.33579 8.75 4.75V7.68934L10.7803 9.71967C11.0732 10.0126 11.0732 10.4874 10.7803 10.7803C10.4874 11.0732 10.0126 11.0732 9.71967 10.7803L7.46967 8.53033C7.32902 8.38968 7.25 8.19891 7.25 8V4.75C7.25 4.33579 7.58579 4 8 4Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 1.5C4.41015 1.5 1.5 4.41015 1.5 8C1.5 11.5899 4.41015 14.5 8 14.5C11.5899 14.5 14.5 11.5899 14.5 8C14.5 4.41015 11.5899 1.5 8 1.5ZM0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2197 0.21967C14.5126 -0.0732233 14.9874 -0.0732233 15.2803 0.21967L15.7803 0.71967C16.0732 1.01256 16.0732 1.48744 15.7803 1.78033C15.4874 2.07322 15.0126 2.07322 14.7197 1.78033L14.2197 1.28033C13.9268 0.987437 13.9268 0.512563 14.2197 0.21967Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.78033 0.21967C2.07322 0.512563 2.07322 0.987437 1.78033 1.28033L1.28033 1.78033C0.987437 2.07322 0.512563 2.07322 0.21967 1.78033C-0.0732233 1.48744 -0.0732233 1.01256 0.21967 0.71967L0.71967 0.21967C1.01256 -0.0732233 1.48744 -0.0732233 1.78033 0.21967Z"
        fill="currentColor"
      />
    </svg>
  );
}
