export function GraphClarifyIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg aria-hidden="true" viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 1.75C1.5 1.33579 1.16421 1 0.75 1C0.335786 1 0 1.33579 0 1.75V14.25C0 14.6642 0.335786 15 0.75 15H15.25C15.6642 15 16 14.6642 16 14.25C16 13.8358 15.6642 13.5 15.25 13.5H1.5V1.75ZM15.7803 4.28033C16.0732 3.98744 16.0732 3.51256 15.7803 3.21967C15.4874 2.92678 15.0126 2.92678 14.7197 3.21967L10 7.93934L7.53033 5.46967C7.23744 5.17678 6.76256 5.17678 6.46967 5.46967L3.21967 8.71967C2.92678 9.01256 2.92678 9.48744 3.21967 9.78033C3.51256 10.0732 3.98744 10.0732 4.28033 9.78033L7 7.06066L9.46967 9.53033C9.61032 9.67098 9.80109 9.75 10 9.75C10.1989 9.75 10.3897 9.67098 10.5303 9.53033L15.7803 4.28033Z"
        fill="currentColor"
      />
    </svg>
  );
}
