export function HomeClarifyIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg aria-hidden="true" viewBox="0 0 16 16" {...props}>
      <path
        d="M2.75 15.25H13.25C14.3546 15.25 15.25 14.3546 15.25 13.25V5.75L8 0.75L0.75 5.75V13.25C0.75 14.3546 1.64543 15.25 2.75 15.25Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M5.74951 11.749C5.74951 10.6445 6.6449 9.74902 7.7495 9.74902H8.2495C9.3541 9.74902 10.2495 10.6445 10.2495 11.749V15.249H5.74951V11.749Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </svg>
  );
}
