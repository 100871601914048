export function WarningClarifyIcon(
  props: React.ComponentPropsWithoutRef<'svg'>,
) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.72106 2.99856C8.97416 1.66715 7.03049 1.66715 6.28359 2.99856L1.7593 11.0331L1.75871 11.0342C1.01402 12.3692 1.97184 14 3.49242 14H12.5122C14.0338 14 14.9879 12.3602 14.2377 11.0374L14.2362 11.0348L9.72106 2.99856ZM11.0293 2.26467C9.70943 -0.0880523 6.29582 -0.0882242 4.97567 2.26416L0.451001 10.2994L0.449731 10.3017C0.141997 10.8526 0.000534506 11.4338 -0.000723947 12C-0.0047858 13.8277 1.45935 15.5 3.5 15.5H12.5C14.5415 15.5 16.0129 13.8289 16.0011 12C15.9974 11.4353 15.8535 10.8464 15.5433 10.2987L15.5425 10.2974L11.0293 2.26467Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00011 4C8.41432 4 8.75011 4.33579 8.75011 4.75V9.25C8.75011 9.66421 8.41432 10 8.00011 10C7.58589 10 7.25011 9.66421 7.25011 9.25V4.75C7.25011 4.33579 7.58589 4 8.00011 4Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 12C9 12.55 8.55 13 8 13C7.45 13 7 12.55 7 12C7 11.45 7.45 11 8 11C8.55 11 9 11.44 9 12Z"
        fill="currentColor"
      />
    </svg>
  );
}
