export function NoteClarifyIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg aria-hidden="true" viewBox="0 0 16 16" {...props}>
      <path
        d="M8.75.75h-5a2 2 0 00-2 2v10.5a2 2 0 002 2h8.5a2 2 0 002-2v-7M8.75.75v3.5a2 2 0 002 2h3.5M8.75.75l5.5 5.5M4.75 11.75h6.5M4.75 8.75h2.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </svg>
  );
}
