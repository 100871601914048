export function GroupViewClarifyIcon(
  props: React.ComponentPropsWithoutRef<'svg'>,
) {
  return (
    <svg aria-hidden="true" viewBox="0 0 16 16" {...props}>
      <path
        d="M2.61207 6.25H13.3879C13.864 6.25 14.25 5.8837 14.25 5.43185V2.56814C14.25 2.1163 13.864 1.75 13.3879 1.75H2.61207C2.13596 1.75 1.75 2.1163 1.75 2.56815V5.43185C1.75 5.8837 2.13596 6.25 2.61207 6.25Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M2.61207 14.25H13.3879C13.864 14.25 14.25 13.8837 14.25 13.4319V10.5681C14.25 10.1163 13.864 9.75 13.3879 9.75H2.61207C2.13596 9.75 1.75 10.1163 1.75 10.5681V13.4319C1.75 13.8837 2.13596 14.25 2.61207 14.25Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </svg>
  );
}
