export function VideoBack10sClarifyIcon(
  props: React.ComponentPropsWithoutRef<'svg'>,
) {
  return (
    <svg aria-hidden="true" viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.21376 6.49998C2.22514 6.50024 2.2365 6.50024 2.24783 6.49998H4.75C5.16421 6.49998 5.5 6.16419 5.5 5.74998C5.5 5.33576 5.16421 4.99998 4.75 4.99998H3.34746C4.29063 3.26563 6.25232 2.09998 8.35424 2.09998C10.809 2.09998 12.9368 3.70246 13.8009 6.01273C13.946 6.40069 14.3782 6.59756 14.7661 6.45244C15.1541 6.30733 15.3509 5.87519 15.2058 5.48722C14.1428 2.64514 11.4907 0.599976 8.35424 0.599976C6.04307 0.599976 3.82446 1.72589 2.5 3.53892V2.14998C2.5 1.73576 2.16421 1.39998 1.75 1.39998C1.33579 1.39998 1 1.73576 1 2.14998V5.74998C1 6.16419 1.33579 6.49998 1.75 6.49998H2.21376Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.53125 14.5247V9.58866H4.50078L3.80508 10.0813C3.58672 10.2235 3.45977 10.2691 3.3125 10.2691C3.0332 10.2691 2.825 10.061 2.825 9.7817C2.825 9.56834 2.95703 9.3805 3.21602 9.20786L4.125 8.57306C4.49062 8.32426 4.78008 8.22266 5.06446 8.22266C5.55703 8.22266 5.88711 8.5629 5.88711 9.07578V14.5247C5.88711 14.9918 5.63828 15.261 5.20664 15.261C4.78008 15.261 4.53125 14.9867 4.53125 14.5247Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.0855 11.9043V11.5793C7.0855 9.49729 8.08086 8.13633 9.73126 8.13633C11.3867 8.13633 12.3617 9.48201 12.3617 11.5793V11.9043C12.3617 13.9813 11.3562 15.3473 9.71094 15.3473C8.06558 15.3473 7.0855 13.9965 7.0855 11.9043ZM8.45662 11.5844V11.8942C8.45662 13.3566 8.93398 14.2301 9.7211 14.2301C10.5082 14.2301 10.9855 13.3566 10.9855 11.8942V11.5844C10.9855 10.127 10.5082 9.25353 9.7211 9.25353C8.93398 9.25353 8.45662 10.1218 8.45662 11.5844Z"
        fill="currentColor"
      />
    </svg>
  );
}
