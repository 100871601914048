export function DeleteClarifyIcon(
  props: React.ComponentPropsWithoutRef<'svg'>,
) {
  return (
    <svg aria-hidden="true" viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.49627 6.67537C4.45506 6.26321 4.08753 5.9625 3.67537 6.00372C3.26321 6.04493 2.9625 6.41247 3.00372 6.82462L3.66367 13.4241C3.75313 14.3187 4.50592 15 5.40498 15H10.595C11.4941 15 12.2469 14.3187 12.3363 13.4241L12.9963 6.82462C13.0375 6.41247 12.7368 6.04493 12.3246 6.00372C11.9125 5.9625 11.5449 6.26321 11.5037 6.67537L10.8438 13.2749C10.831 13.4027 10.7234 13.5 10.595 13.5H5.40498C5.27655 13.5 5.169 13.4027 5.15622 13.2749L4.49627 6.67537Z"
        fill="currentColor"
      />
      <g className="transition-transform group-hover/trash:translate-x-[-.75px] group-hover/trash:translate-y-[-1.25px] group-hover/trash:rotate-[-5deg]">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.9071 4H2.25C1.83579 4 1.5 4.33579 1.5 4.75C1.5 5.16421 1.83579 5.5 2.25 5.5H13.75C14.1642 5.5 14.5 5.16421 14.5 4.75C14.5 4.33579 14.1642 4 13.75 4H11.0923V3.91667C11.0923 2.56658 9.97821 1.5 8.63862 1.5H7.36081C6.0213 1.5 4.9071 2.56653 4.9071 3.91667V4ZM6.4071 3.91667C6.4071 3.42587 6.81847 3 7.36081 3H8.63862C9.18094 3 9.59225 3.42582 9.59225 3.91667V4H6.4071V3.91667Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
