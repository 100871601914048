export function UnreadClarifyIcon(
  props: React.ComponentPropsWithoutRef<'svg'>,
) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M14.25 7.13793V12.5259C14.25 13.4781 13.4781 14.25 12.5259 14.25H3.47411C2.52191 14.25 1.75 13.4781 1.75 12.5259V3.47414C1.75 2.52192 2.52191 1.75 3.47411 1.75H8.86197"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5 2.5C15.5 1.39543 14.6046 0.5 13.5 0.5C12.3954 0.5 11.5 1.39543 11.5 2.5C11.5 3.60457 12.3954 4.5 13.5 4.5C14.6046 4.5 15.5 3.60457 15.5 2.5Z"
        fill="currentColor"
      />
    </svg>
  );
}
