export function VideoPlayClarifyIcon(
  props: React.ComponentPropsWithoutRef<'svg'>,
) {
  return (
    <svg aria-hidden="true" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M13.5642 6.87484L5.06712 1.96721C4.06811 1.39022 2.75 2.03029 2.75 3.0924V12.9076C2.75 13.9697 4.0681 14.6098 5.06712 14.0328L13.5642 9.12516C14.4786 8.59705 14.4786 7.40294 13.5642 6.87484Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  );
}
