export function VideoForward10sClarifyIcon(
  props: React.ComponentPropsWithoutRef<'svg'>,
) {
  return (
    <svg aria-hidden="true" viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.797553 5.48722C1.86062 2.64514 4.51265 0.599976 7.64915 0.599976C9.96032 0.599976 12.1789 1.72589 13.5034 3.53893V2.14998C13.5034 1.73576 13.8392 1.39998 14.2534 1.39998C14.6676 1.39998 15.0034 1.73576 15.0034 2.14998V5.74998C15.0034 6.16419 14.6676 6.49998 14.2534 6.49998H11C10.5858 6.49998 10.25 6.16419 10.25 5.74998C10.25 5.33576 10.5858 4.99998 11 4.99998H12.6559C11.7128 3.26563 9.75107 2.09998 7.64915 2.09998C5.19438 2.09998 3.06663 3.70246 2.20249 6.01273C2.05737 6.40069 1.62523 6.59756 1.23727 6.45244C0.849304 6.30733 0.652438 5.87518 0.797553 5.48722Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.93127 14.5246V9.58863H4.9008L4.2051 10.0813C3.98674 10.2234 3.85979 10.2691 3.71252 10.2691C3.43322 10.2691 3.22502 10.061 3.22502 9.78167C3.22502 9.56831 3.35705 9.38047 3.61604 9.20783L4.52502 8.57303C4.89064 8.32423 5.1801 8.22263 5.46448 8.22263C5.95705 8.22263 6.28713 8.56287 6.28713 9.07575V14.5246C6.28713 14.9918 6.0383 15.261 5.60666 15.261C5.1801 15.261 4.93127 14.9867 4.93127 14.5246Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.48552 11.9043V11.5793C7.48552 9.49726 8.48088 8.1363 10.1313 8.1363C11.7867 8.1363 12.7617 9.48198 12.7617 11.5793V11.9043C12.7617 13.9813 11.7562 15.3473 10.111 15.3473C8.4656 15.3473 7.48552 13.9965 7.48552 11.9043ZM8.85664 11.5844V11.8941C8.85664 13.3566 9.334 14.2301 10.1211 14.2301C10.9082 14.2301 11.3855 13.3566 11.3855 11.8941V11.5844C11.3855 10.1269 10.9082 9.2535 10.1211 9.2535C9.334 9.2535 8.85664 10.1218 8.85664 11.5844Z"
        fill="currentColor"
      />
    </svg>
  );
}
