export function CommentClarifyIcon(
  props: React.ComponentPropsWithoutRef<'svg'>,
) {
  return (
    <svg aria-hidden="true" viewBox="0 0 16 16" {...props}>
      <path
        d="M8 13.8962C11.5994 13.8962 14.75 11.9338 14.75 7.57308C14.75 3.21233 11.5994 1.25 8 1.25C4.40063 1.25 1.25 3.21233 1.25 7.57308C1.25 9.17476 1.67504 10.4529 2.39124 11.4291C2.87931 12.18 2.5963 12.6086 2.38868 12.996C1.94828 14.0405 2.41379 14.9707 3.85105 14.7037C4.61488 14.5276 5.41608 14.2892 6.11722 13.9694C6.37393 13.8523 6.65737 13.8051 6.93743 13.837C7.28535 13.8766 7.64043 13.8962 8 13.8962Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M8.00063 13.8201C11.6003 13.8201 14.7513 11.8696 14.7513 7.53506C14.7513 3.20054 11.6003 1.25 8.00063 1.25C4.40092 1.25 1.25 3.20054 1.25 7.53506C1.25 9.18079 1.70422 10.4829 2.46478 11.465C2.68726 11.7523 2.77565 12.134 2.62163 12.4632C2.52783 12.6636 2.42911 12.8549 2.32817 13.0363C1.90522 13.7961 2.48793 14.9031 3.34061 14.7324C4.28667 14.543 5.34462 14.2596 6.22724 13.8423C6.41146 13.7552 6.6164 13.7213 6.81856 13.747C7.20453 13.7959 7.59979 13.8201 8.00063 13.8201Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </svg>
  );
}
