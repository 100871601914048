export function TypographyClarifyIcon(
  props: React.ComponentPropsWithoutRef<'svg'>,
) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.20963 8.5L4.57436 3.59417L2.85732 8.5H6.20963ZM6.70963 10L7.53869 12.4872C7.66968 12.8801 8.09442 13.0925 8.48738 12.9615C8.88033 12.8305 9.0927 12.4058 8.96172 12.0128L5.73462 2.33155C5.56909 1.83496 5.10436 1.5 4.58091 1.5C4.06413 1.5 3.60378 1.8266 3.43306 2.31438L0.0423106 12.0022C-0.094525 12.3932 0.111483 12.8211 0.502442 12.9579C0.8934 13.0947 1.32126 12.8887 1.4581 12.4978L2.33232 10H6.70963ZM9.84087 5.64243C10.5288 4.37408 11.8692 4 13.0001 4C14.4999 4 16.0001 4.93852 16.0001 6.60109V12.25C16.0001 12.6642 15.6644 13 15.2501 13C14.933 13 14.6619 12.8032 14.5522 12.525C13.9952 12.8197 13.3005 13 12.5 13C11.7301 13 10.9864 12.769 10.4218 12.2913C9.84472 11.803 9.5 11.0924 9.5 10.25C9.5 9.55605 9.76509 8.83855 10.3869 8.30556C10.9999 7.7801 11.8811 7.5 13 7.5H14.5001V6.60109C14.5001 6.06148 14.0004 5.5 13.0001 5.5C12.1311 5.5 11.4715 5.78217 11.1594 6.35757C10.9619 6.72168 10.5067 6.85676 10.1426 6.65927C9.77846 6.46179 9.64339 6.00653 9.84087 5.64243ZM14.5001 9H13C12.1189 9 11.6251 9.2199 11.3631 9.44444C11.1099 9.66145 11 9.94395 11 10.25C11 10.6576 11.1553 10.947 11.3907 11.1462C11.6386 11.356 12.0199 11.5 12.5 11.5C13.2321 11.5 13.7604 11.2915 14.0877 11.0514C14.4388 10.794 14.5 10.5563 14.5 10.5H14.5001V9Z"
        fill="currentColor"
      />
    </svg>
  );
}
