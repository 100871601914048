export function SparkleClarifyIcon(
  props: React.ComponentPropsWithoutRef<'svg'>,
) {
  return (
    <svg aria-hidden="true" viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6039 9.26922C11.7451 8.91026 12.2549 8.91026 12.3961 9.26922L12.9869 10.7714C13.0299 10.8807 13.1165 10.9673 13.226 11.0106L14.7316 11.6061C15.0895 11.7477 15.0895 12.2523 14.7316 12.3939L13.226 12.9893C13.1165 13.0327 13.0299 13.1193 12.9869 13.2286L12.3961 14.7308C12.2549 15.0897 11.7451 15.0897 11.6039 14.7308L11.0131 13.2286C10.9701 13.1193 10.8835 13.0327 10.774 12.9893L9.26843 12.3939C8.91052 12.2523 8.91052 11.7477 9.26843 11.6061L10.774 11.0106C10.8835 10.9673 10.9701 10.8807 11.0131 10.7714L11.6039 9.26922Z"
        fill="currentColor"
        opacity="0.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.09471 1.27521C5.23915 0.908263 5.76086 0.908263 5.9053 1.27521L6.91438 3.83863C6.95839 3.95037 7.04698 4.03897 7.15907 4.08325L9.72533 5.09733C10.0916 5.24204 10.0916 5.75797 9.72533 5.90268L7.15907 6.91676C7.04698 6.96104 6.95839 7.0496 6.91438 7.16138L5.9053 9.7248C5.76086 10.0917 5.23915 10.0917 5.09471 9.7248L4.08563 7.16138C4.04166 7.0496 3.95303 6.96104 3.84094 6.91676L1.27468 5.90268C0.908441 5.75797 0.908441 5.24204 1.27468 5.09733L3.84094 4.08325C3.95303 4.03897 4.04166 3.95037 4.08563 3.83863L5.09471 1.27521Z"
        fill="currentColor"
      />
    </svg>
  );
}
