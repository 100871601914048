export function VideoVolumeFullClarifyIcon(
  props: React.ComponentPropsWithoutRef<'svg'>,
) {
  return (
    <svg aria-hidden="true" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M12.9863 3.18243C14.2305 4.41532 15 6.11854 15 7.99988C15 9.88116 14.2305 11.5844 12.9863 12.8173M10.7335 5.41531C11.4011 6.07686 11.814 6.99077 11.814 8.00027C11.814 9.00978 11.4011 9.9237 10.7335 10.5852M2.59146 5.17515H3.66793C3.94925 5.17515 4.22345 5.0875 4.45175 4.92461L7.76133 2.56322C7.98321 2.40494 8.29268 2.56204 8.29268 2.83293V13.1671C8.29268 13.438 7.98321 13.5951 7.76133 13.4368L4.45175 11.0754C4.22345 10.9125 3.94925 10.8249 3.66793 10.8249H2.59146C1.85059 10.8249 1.25 10.2297 1.25 9.49552V6.50449C1.25 5.77032 1.85059 5.17515 2.59146 5.17515Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
