export function EditClarifyIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg aria-hidden="true" viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0126 1.42675C11.696 0.743329 12.804 0.743331 13.4874 1.42675L14.5732 2.51254C15.2566 3.19595 15.2566 4.30399 14.5732 4.98741L5.96355 13.5971C5.75325 13.8074 5.49283 13.9606 5.20687 14.0423L1.95603 14.9711C1.69413 15.046 1.41226 14.9729 1.21966 14.7803C1.02706 14.5877 0.954019 14.3059 1.02884 14.044L1.95766 10.7931C2.03936 10.5072 2.19259 10.2467 2.40289 10.0364L11.0126 1.42675ZM12.4268 2.48741C12.3291 2.38978 12.1708 2.38978 12.0732 2.48741L10.8106 3.74997L12.25 5.18931L13.5126 3.92675C13.6102 3.82912 13.6102 3.67083 13.5126 3.5732L12.4268 2.48741ZM11.1893 6.24997L9.74999 4.81063L3.46355 11.0971C3.43351 11.1271 3.41162 11.1643 3.39994 11.2052L2.842 13.158L4.79479 12.6C4.83564 12.5884 4.87284 12.5665 4.90289 12.5364L11.1893 6.24997Z"
        fill="currentColor"
      />
    </svg>
  );
}
