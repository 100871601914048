export function ErrorCircleClarifyIcon(
  props: React.ComponentPropsWithoutRef<'svg'>,
) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00002 1.50001C4.41017 1.50001 1.50001 4.41016 1.50001 8.00001C1.50001 11.5899 4.41016 14.5 8.00002 14.5C11.5899 14.5 14.5 11.5899 14.5 8.00001C14.5 4.41015 11.5899 1.50001 8.00002 1.50001ZM0 8.00001C0 3.58173 3.58174 0 8.00002 0C12.4183 0 16 3.58173 16 8.00001C16 12.4183 12.4183 16 8.00002 16C3.58174 16 0 12.4183 0 8.00001Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 3.25C8.41422 3.25 8.75 3.58579 8.75 4V9C8.75 9.41422 8.41422 9.75 8 9.75C7.58579 9.75 7.25 9.41422 7.25 9V4C7.25 3.58579 7.58579 3.25 8 3.25Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 11.5C9 12.0514 8.5514 12.5 8 12.5C7.44861 12.5 7 12.0514 7 11.5C7 10.9486 7.44861 10.5 8 10.5C8.5514 10.5 9 10.9486 9 11.5Z"
        fill="currentColor"
      />
    </svg>
  );
}
